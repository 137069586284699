import Backdrop from "@material-ui/core/Backdrop"
import React, { useEffect, useState } from "react"
import { WorkoutSelectedTypes, WorkoutVideo } from "../types"
import { WorkoutIntroWithDivider } from "../../videochat/WorkoutWaitRoom/WorkoutWait"
import WorkoutImage, { width, widthSM, widthXL, widthXS } from "../WorkoutCard/WorkoutImage/WorkoutImage"
import { makeStyles } from "@material-ui/core/styles"
import CloseButton from "../../../components/CloseButton"
import StyledButton from "../../../components/StyledButton"
import { getClassDetailUrl, getSoloUrl } from "../../routing/Locations"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import { clearErrors, createGroupWorkout, reset, soloWorkoutSelected } from "./workoutModeSlice"
import { RootState } from "../../../redux/store"
import { CircularProgress } from "@material-ui/core"
import StyledSnackbar from "../../../components/StyledSnackbar"
import { isParnterClientUser, isSafari } from "../../../utils"
import WorkoutSEO from "../../../components/WorkoutSEO"
import InviteFriendsSuccess from "../Scheduled/InviteFriendsSuccess"
import AddToCalendar, { convertWorkoutToCalendarProps } from "../Scheduled/AddToCalendar"


export interface Props {
  workout: WorkoutVideo | undefined | null
  selectionType: WorkoutSelectedTypes
  backurl: string
  cleanup: () => void
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItem: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(0, 0, 0, 0.92)"
  },
  imageContainer: {
    display: "inline-block",
    verticalAlign: "top",

    [theme.breakpoints.down("xs")]: {
      display: "block"
    }
  },
  textContainer: {
    width: `${width * 0.8}px`,
    display: "inline-block",
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: `${widthXS}px`,
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(0)
    },
    [theme.breakpoints.only("sm")]: {
      width: `${widthSM * 1.4}px`
    },
    [theme.breakpoints.up("xl")]: {
      width: `${widthXL * 0.8}px`
    }
  },

  info: {
    marginTop: theme.spacing(1)
  },
  soloButton: {
    marginTop: theme.spacing(2)
  },
  groupButton: {
    marginTop: theme.spacing(4)
  },
  calendar: {
    marginTop: theme.spacing(2)
  }
}))

type workoutModeType = "single" | "group" | null


export default function ChooseWorkoutMode(props: Props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [workoutMode, setWorkoutMode] = useState<workoutModeType>(null)
  const state = useSelector((state: RootState) => state.workoutMode)

  const isMbsHotelUser = isParnterClientUser()

  useEffect(() => {
    if (props.selectionType == "friends-invite") {
      dispatch(createGroupWorkout(props.workout!))
    }
  }, [props.selectionType])


  useEffect(() => {
    if (state.newGroupUrl) {
      if (isSafari && window != undefined) {
        window.location.href = state.newGroupUrl
      } else {
        navigate(state.newGroupUrl)
      }
      clearSelection("group")
    }
  }, [state.newGroupUrl])

  useEffect(() => {
    if (workoutMode === "single") {
      dispatch(soloWorkoutSelected())
      navigate(getSoloUrl(props.workout!.id, props.workout!.scheduleInfo?.id), { replace: true })
      clearSelection(workoutMode)
    } else if (workoutMode === "group") {
      dispatch(createGroupWorkout(props.workout!))
    }

    const onPopState = () => {
      if (workoutMode == null) {
        clearSelection(null)
      }
    }

    window.addEventListener("popstate", onPopState)
    return () => {
      window.removeEventListener("popstate", onPopState)
    }

  }, [workoutMode])


  useEffect(() => {
    if (window !== undefined && props.workout)
      window.history.pushState("", "Choose class type", getClassDetailUrl(props.workout!.id, props.workout!.scheduleInfo?.id))
  }, [props.workout])


  function clearSelection(mode: workoutModeType) {
    if (mode == null) {
      resetHistory()
    }
    props.cleanup()
    dispatch(reset())
  }

  function resetHistory() {
    window.history.replaceState("", "Classes", props.backurl)
  }

  function classInfo() {
    return (<div className={classes.container}>

      <div className={classes.imageContainer}>
        <WorkoutImage workoutVideo={props.workout!} large={true} alwaysShowGif={true} fixed={true}/>
      </div>
      <div className={classes.textContainer}>
        <WorkoutIntroWithDivider title={props.workout!.title} subtitle={props.workout!.subtitle}
                                 classTimestampInMS={props.workout!.scheduleInfo?.startTimeInMs}
                                 description={props.workout!.description} showWorkoutInfo={true}/>
        {props.selectionType == "on-demand" && onDemandBottom()}
        {props.selectionType == "schedule-detail" && scheduledBottom()}
      </div>
    </div>)
  }

  function onDemandBottom() {
    if(isMbsHotelUser){
      return (
        <StyledButton className={classes.soloButton} text={"Start Class"} onClick={() => {
          setWorkoutMode("single")
        }}/>)
    }
    return (<>
      <StyledButton className={classes.groupButton} text={"live with friends"} onClick={() => {
        setWorkoutMode("group")
      }}/>
      <StyledButton className={classes.soloButton} text={"Going Solo"} onClick={() => {
        setWorkoutMode("single")
      }}/>
    </>)
  }

  function scheduledBottom() {
    return props.workout?.scheduleInfo!.ctaState === "invite" ? (<>
      <StyledButton className={classes.groupButton} text={"invite your friends"}
                    onClick={() => dispatch(createGroupWorkout(props.workout!))}/>
      <div className={classes.calendar}>
        <AddToCalendar {...convertWorkoutToCalendarProps(props.workout, "left")}/>
      </div>
    </>) : onDemandBottom()
  }


  return (
    <Backdrop open={props.workout != null} className={classes.backdrop}>
      {props.workout && <WorkoutSEO workoutVideo={props.workout}/>}
      {props.workout && !state.loading && !state.inviteFriends && classInfo()}
      {props.workout && !state.loading && state.inviteFriends &&
      <InviteFriendsSuccess workout={props.workout!} inviteLink={state.inviteLink!} inviteText={state.inviteText!}/>}
      {state.loading && <CircularProgress/>}
      {state.error && <StyledSnackbar show={true} severity={"error"} message={state.error.message} onClose={() => {
        dispatch(clearErrors())
        setWorkoutMode(null)
      }
      }/>}
      <CloseButton clicked={() => clearSelection(workoutMode)}/>
    </Backdrop>
  )
}
